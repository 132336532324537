<template>
    <div>
        <MyResume/>
        <section class="container-fluid g-px-70--lg g-px-50--sm g-pt-10">
            <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <h5 class="d-flex align-items-center g-color-gray-dark-v4 mb-0">
                        <p class="g-font-size-20--sm g-font-size-26--lg">
                            <router-link :to="{name: 'resume'}">
                                <img src=" assets/img/arrow.svg">
                            </router-link>
                            Reclamos realizados
                        </p>
                    </h5>
                </div>
                <div class="col-sm-6 col-lg-6 text-center">
                    <router-link to="#" v-on:click.native="beginCreateNewClaim" class="btn btn-md u-btn-outline-teal g-mr-10 g-mb-15 ">
                        Ingresar nuevo reclamo <img src="assets/img/msg-plus.svg ">
                    </router-link>
                </div>
            </div>
            <hr class="g-my-10 " style="border: 1px solid; ">
        </section>

        <section class="container-fluid g-px-100 g-pt-90 g-pb-30" v-if="page.loading">
            <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </section>  

        <section class="container-fluid g-px-80--lg g-px-50--sm g-pt-40 g-mb-100" v-else>
            <div class="row ">
                <div class="col-sm-12 col-lg-12 ">
                    <div class="table-responsive ">
                        <table class="table table-bordered ">
                            <thead>
                                <tr class="g-bg-cyan g-color-white ">
                                    <th>Folio</th>
                                    <th>Institución</th>
                                    <th>Producto</th>
                                    <th>Fecha ingreso</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="claim in userClaims" :key="claim.id">
                                    <td class="g-color-blue ">
                                        <v-progress-linear buffer-value="0" stream color="cyan" v-if="form.processing"></v-progress-linear>
                                        <router-link to="#" v-on:click.native="gotoClaim(claim.id, claim.rut)" v-else>
                                            {{claim.internalFolio}}
                                        </router-link>
                                    </td>
                                    <td>{{getBankName(claim.bank.code)}}</td>
                                    <td>
                                        <span v-for="productCode in claim.detail.productCodes" :key="productCode" class="mr-2">
                                            {{getProductName(productCode)}}
                                        </span>
                                    </td>
                                    <td>{{claim.creationDate | formatDateShort}}</td>
                                    <td class="orange--text" v-if="allConstants.dashboard.groups.claimant.claimsActive.statuses.includes(claim.state)">
                                       <span class="blue--text" v-if="claim.state == 'INGRESADO'">{{claim.lastActionCode | wfStatusForClaimant}}</span> 
                                       <span v-else>{{claim.lastActionCode | wfStatusForClaimant}}</span>
                                    </td>
                                    <td class="green--text" v-if="allConstants.dashboard.groups.claimant.claimsSolved.statuses.includes(claim.state)">
                                       <span>{{claim.lastActionCode | wfStatusForClaimant}}</span> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import baseViewMixin from "@/mixins/baseView";
    import parametersMixin from "@/mixins/parameters";
    import currentUserMixin from "@/mixins/currentUser"; 
    import currentClaimInCreationMixin from "@/mixins/currentClaimInCreation";
    import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
    
    import MyResume from "@/components/PublicSite/User/MyResume";

    import claimsService from "@/services/claims";

    export default {
        mixins: [baseViewMixin, parametersMixin, currentUserMixin, currentClaimInCreationMixin, currentClaimSelectedMixin],
        components: {MyResume},
        props: {
            macroStatusesToInclude: {
                type: Array
            },
        },
        data() {
            return {
                formFields: {
                },
                userClaims: [],
            }
        },
        methods: {
            async beginCreateNewClaim() {
                const isRegisteredUser = true; 
                const fromPublicSite = true;

                await this.startCurrentClaimInCreation(isRegisteredUser, fromPublicSite);

                let currentClaimInCreation = this.currentClaimInCreation;
                currentClaimInCreation.userType = this.currentUserInfo.userType;
                
                await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);

                this.replaceView("claimCreationFlow");
            },
            async gotoClaim(claimId, userIdentification) {
                this.initSignalProcessing();

                await this.loadClaim(claimId, userIdentification);

                this.gotoView("claimdetail");
            },
            gotoMain() {
                this.gotoView("resume", {id: this.$uuid.v4()});
            }
        },
        async beforeMount() {
            this.initSignalLoading();

            let macroStatusesFilter = this.macroStatusesToInclude;
            if (!macroStatusesFilter) {
                if (this.currentPage.routerName == this.$route.name && this.currentPage.macroStatusesToInclude) {
                    macroStatusesFilter = this.currentPage.macroStatusesToInclude;
                }
            }

            const getResult = await claimsService.getByIdentification(this.currentUserInfo.userName);
            this.userClaims = getResult.data.filter(claim => macroStatusesFilter.includes(claim.state));
            
            let currentPageData = this.currentPage;
            currentPageData.routerName = this.$route.name;
            currentPageData.macroStatusesToInclude = macroStatusesFilter;
            this.createOrUpdateCurrentPage(currentPageData);

            this.stopSignalLoading();
        }
    }
</script>

<style lang="scss" scoped>

</style>